@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  scroll-behavior: smooth;
}

body {
  background-color: #f2f6fc;
  background: #f0f6ff;
  scroll-behavior: smooth;
}

.english {
  font-family: "Public Sans", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Helvetica Neue";
}

.arabic {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Noto Sans Arabic", "Helvetica Neue";
}

input,
select {
  outline: none;
}

/* My Custom Style */

@layer components {
  .badge {
    @apply absolute right-[-10px] top-[-6px] rounded-full text-main font-[600] bg-white text-[12px] flex justify-center items-center  border-2 border-main w-[20px] h-[20px];
  }
}

.main-container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.main-container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

/* For tablets */
@media (max-width: 768px) {
  .main-container {
    width: 95%;
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .main-container {
    width: 97%;
  }
}

.shadow-custom {
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.12);
}

/* React Carousel Style */

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 0 !important;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.ImagePromtion {
  background-image: url("../src/Assests/image/11.jpg");
  background-size: contain;
}

.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
